export const firebaseConfig = {
  apiKey: 'AIzaSyDeJ2p42HlYPryg_6wDz6tn9uey4IWgEhY',
  authDomain: 'live-code-share.firebaseapp.com',
  databaseURL: 'https://live-code-share-default-rtdb.firebaseio.com',
  projectId: 'live-code-share',
  storageBucket: 'live-code-share.appspot.com',
  messagingSenderId: '1038664311820',
  appId: '1:1038664311820:web:eb448b9e7dd6d029d57a29',
  measurementId: 'G-QH885N106L',
};
